import { Sizes } from '../../style/themes/theme.types'
import * as styles from './Thumbnail.styles'

export type ThumbnailProps = {
  src: string
  size: keyof Sizes
}

export default function Thumbnail({ src, size }: ThumbnailProps) {
  return <div css={styles.thumbnail({ src, size })} />
}
