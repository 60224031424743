import { ThumbnailProps } from './Thumbnail'

export const thumbnail: StyleWithOptions<ThumbnailProps> =
  ({ src, size }) =>
  ({ backgroundColor, breakpoint, ...theme }) => ({
    zIndex: 10,
    width: theme.size[size],
    height: theme.size[size],
    borderRadius: '1em',
    backgroundImage: `url(${src})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    overflow: 'hidden',
    border: `1px solid ${backgroundColor.primary}`,

    [breakpoint.small]: {
      border: `1px solid ${backgroundColor.primary}`,
    },
  })
