/* eslint-disable */
import * as Types from '../types.gen'

import { gql } from '@apollo/client/index.js'
import { ArtistDetailsFragmentDoc } from '../fragments/artistDetails.fragment.gen'
import { VenueDetailsFragmentDoc } from '../fragments/venueDetails.fragment.gen'
import * as Apollo from '@apollo/client/index.js'
const defaultOptions = {} as const
export type SetSelectedProfileMutationVariables = Types.Exact<{
  slug: Types.Scalars['String']['input']
}>

export type SetSelectedProfileMutation = {
  __typename?: 'Mutation'
  setSelectedProfile?:
    | {
        __typename: 'Artist'
        id: string
        name: string
        description: string
        picture?: string | null
        slug: string
        genres: Array<{ __typename?: 'Genre'; id: number; name: string }>
        links: Array<{ __typename?: 'Link'; name: string; url: string }>
      }
    | { __typename?: 'Promoter' }
    | { __typename?: 'User' }
    | {
        __typename: 'Venue'
        id: string
        name: string
        description: string
        picture?: string | null
        address: string
        slug: string
        audienceCapacity?: number | null
        ageRestriction?: Types.AgeRestriction | null
        city: { __typename?: 'City'; id: number; name: string }
        links: Array<{ __typename?: 'Link'; name: string; url: string }>
      }
    | null
}

export const SetSelectedProfileDocument = gql`
  mutation setSelectedProfile($slug: String!) {
    setSelectedProfile(slug: $slug) {
      ... on Artist {
        ...ArtistDetails
      }
      ... on Venue {
        ...VenueDetails
      }
    }
  }
  ${ArtistDetailsFragmentDoc}
  ${VenueDetailsFragmentDoc}
`
export type SetSelectedProfileMutationFn = Apollo.MutationFunction<
  SetSelectedProfileMutation,
  SetSelectedProfileMutationVariables
>

/**
 * __useSetSelectedProfileMutation__
 *
 * To run a mutation, you first call `useSetSelectedProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSelectedProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSelectedProfileMutation, { data, loading, error }] = useSetSelectedProfileMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useSetSelectedProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetSelectedProfileMutation,
    SetSelectedProfileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SetSelectedProfileMutation,
    SetSelectedProfileMutationVariables
  >(SetSelectedProfileDocument, options)
}
export type SetSelectedProfileMutationHookResult = ReturnType<
  typeof useSetSelectedProfileMutation
>
export type SetSelectedProfileMutationResult =
  Apollo.MutationResult<SetSelectedProfileMutation>
export type SetSelectedProfileMutationOptions = Apollo.BaseMutationOptions<
  SetSelectedProfileMutation,
  SetSelectedProfileMutationVariables
>
