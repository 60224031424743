import { useNavigate } from '@remix-run/react'

import Button from '../../components/Button'
import currentUserQuery from '../../graphql/queries/user/currentUser.query'
import Thumbnail from '../../components/Thumbnail/Thumbnail'
import LoadingSpinner from '../../components/LoadingSpinner'
import { getProfileUrl } from '../../utilities/userUtils'
import { UserProfile } from '../../graphql/fragments/userDetails.fragment'
import { UserDetailsFragment } from '../../graphql/fragments/userDetails.fragment.gen'
import { useSetSelectedProfileMutation } from '../../graphql/mutations/setSelectedProfile.mut.gen'

type Props = {
  profile: UserDetailsFragment['profiles'][0]
  isSelected?: boolean
  composeCss?: Style
}

export const SelectProfileButton = ({
  profile,
  isSelected,
  composeCss,
}: Props) => {
  const [setSelectedProfile, mutationResult] = useSetSelectedProfileMutation()
  const navigate = useNavigate()
  const { picture, name, slug } = profile

  return (
    <Button
      composeCss={composeCss}
      onClick={async () => {
        const result = await setSelectedProfile({
          variables: { slug },
          refetchQueries: [{ query: currentUserQuery }],
        })

        const selectedProfile = result.data?.setSelectedProfile
        if (selectedProfile && selectedProfile.__typename !== 'User') {
          const profileUrl = getProfileUrl(selectedProfile as UserProfile)
          navigate(profileUrl)
        }
      }}
      primary={isSelected}
      fullWidth
      disabled={mutationResult.loading}
    >
      {mutationResult.loading ? (
        <LoadingSpinner />
      ) : (
        picture && <Thumbnail src={picture} size="M" />
      )}
      {name}
    </Button>
  )
}
